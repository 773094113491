import React from "react";
import { Text } from 'native-base';

export default function Paragraph({text, ml=5, mr=5, mt=7, mb=0}) {
    return (
        <Text
            fontSize={{
                base: "18",
                md: "20",
                lg: "22",
                xl: "26"
            }}
            color="black:800"
            ml={ml} mr={mr} mt={mt} mb={mb}
        >
            {text}
        </Text>
    );
}
