import React from "react";
import { useNavigation } from '@react-navigation/native';
import CarouselCard from "../cards/CarouselCard";
import Carousel from "../carousel/Carousel";
import Subtitle from "./Subtitle";
import { Box } from "native-base";

export default function PageCarousel({pages, space=5, ml=0, mr=0, mt=0, mb=0}) {
    const navigation = useNavigation();
    if (pages.length == 0) {
        return <></>;
    }
    return (
        <Box ml={ml} mr={mr} mt={mt} mb={mb}>
            <Subtitle text={'Érdekességek'} ml={space} mr={space} mt={0} mb={0}/>
            <Carousel
                data={pages}
                renderItem={({item}) => <CarouselCard image={item.image} title={item.title} onPress={() => navigation.push('Page', {slug: item.slug})}/>}
                dataKey="id"
                space={space}
            />
        </Box>
    )
}
