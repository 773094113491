import React from "react";
import { Box, Button } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import Screen from "./Screen";
import HeaderImage from "../content_blocks/HeaderImage";
import { useQuery } from "react-query";
import { getRecommendedWineries } from "../../services/winery/wineryApi";
import { getRecommendedWines } from "../../services/winery/wineApi";
import { getRecommendedPlaces } from "../../services/winery/placeApi";
import { getRecommendedPages } from "../../services/winery/pageApi";
import Title from "../content_blocks/Title";
import WineCarousel from "../content_blocks/WineCarousel";
import WineryCarousel from "../content_blocks/WineryCarousel";
import PlaceCarousel from "../content_blocks/PlaceCarousel";
import PageCarousel from "../content_blocks/PageCarousel";
import urls from "../../services/urls";

export default function Home() {
    const navigation = useNavigation();
    const { data: wines=[] } = useQuery(['/wine/recommended', 'getRecommendedWines'], () => getRecommendedWines());
    const { data: wineries=[] } = useQuery(['/winery/recommended', 'getRecommendedWineries'], () => getRecommendedWineries());
    const { data: places=[] } = useQuery(['/place/recommended', 'getRecommendedPlaces'], () => getRecommendedPlaces());
    const { data: pages=[] } = useQuery(['/page/recommended', 'getRecommendedPages'], () => getRecommendedPages());
    return (
        <Screen>
            <HeaderImage source={{ uri: urls.imageBase + '/tokaj-hegyaljai-borvidek.jpg'}}/>
            <Box alignItems="center" mb="5">
                <Title text={'Tokaj-hegyaljai Borvidék'} mt="5" mb="3" ml="5" mr="5"/>
                <Button mb="5" ml="5" mr="5" background="primary.500" onPress={() => navigation.push('Page', { slug: 'tokaj-hegyaljai-borvidek-bemutatasa'})}>Ismertető</Button>
            </Box>
            <WineCarousel wines={wines}/>
            <WineryCarousel wineries={wineries}/>
            <PlaceCarousel places={places}/>
            <PageCarousel pages={pages}/>
        </Screen>
    );
}
