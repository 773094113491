import React from "react";
import { Box, Center, Image, Stack, Text } from 'native-base';
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import urls from "../../services/urls";

export default function SearchResultCard({image, title, description, onPress}) {
    const imageSource = {
        'uri': urls.imageBase + '/' + image,
    }
    return (
        <TouchableWithoutFeedback onPress={onPress}>
            <Box height="355px" bg="white" rounded="lg" borderColor="black" p={5} style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
                <Stack space={5}>
                    <Image alignSelf="center" source={imageSource} alt=" " resizeMode="contain" width="100%" height="150px" />
                    <Text numberOfLines={2} color="gray.600">{title}</Text>
                    <Text numberOfLines={4} color="gray.400">{description}</Text>
                </Stack>
            </Box>
        </TouchableWithoutFeedback>
    );
}
