import React from "react";
import Screen from "./Screen";
import { useQuery } from "react-query";
import ContentBlocks from "../content_blocks/ContentBlocks";
import { useNavigation } from '@react-navigation/native';
import CenteredImage from "../content_blocks/CenteredImage";
import Title from "../content_blocks/Title";
import WineCarousel from "../content_blocks/WineCarousel";
import WineryCarousel from "../content_blocks/WineryCarousel";
import { getPlace } from "../../services/winery/placeApi";

export default function Place({route}) {
    const navigation = useNavigation();
    const { data } = useQuery(['/place', route.params.slug, 'getPlace'], () => getPlace(route.params.slug), {
        onSuccess: (data) => {
            navigation.setOptions({title: data?.title});
        },
        onError: () => {
            navigation.setOptions({title: 'Hiányzó oldal'});
        }
    });
    return (
        <Screen>
            {
                data &&
                <>
                    <CenteredImage image={data.image} width={data.imageWidth} height={data.imageHeight} />
                    <Title text={data.title}/>
                    <ContentBlocks contentBlocks={data.contentBlocks} mb={0}/>
                    <WineCarousel wines={data.wines} mt={0}/>
                    <WineryCarousel wineries={data.wineries} mt={0}/>
                </>
            }
        </Screen>
    );
}
