import React from "react";
import { Button, extendTheme, NativeBaseProvider, StatusBar } from "native-base";
import { NavigationContainer, DefaultTheme as NavigationDefaultTheme } from '@react-navigation/native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import MainScreens from "./components/screens/MainScreens";
import { createStackNavigator } from '@react-navigation/stack';
import Page from "./components/screens/Page";
import appConfig from "./config/app.json";
import reactNavigationConfig from "./config/reactNavigation.json";
import Wine from "./components/screens/Wine";
import Winery from "./components/screens/Winery";
import Place from "./components/screens/Place";
import { QueryClient, QueryClientProvider } from 'react-query';
import HomeButton from "./components/screens/HomeButton";

const Stack = createStackNavigator();
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
        },
    }
});

export default function App() {

    const theme = extendTheme({
        colors: {
            primary: {
                50: '#FFE7E7',
                100: '#FFCCCC',
                200: '#FFABAB',
                300: '#FF8282',
                400: '#FF4A4A',
                500: '#FF0000',
                600: '#FF0000',
                700: '#FE0000',
                800: '#D10000',
                900: '#920007',
            },
        },
        config: {
            initialColorMode: 'light',
        },
    });

    const navigationTheme = {
        ...NavigationDefaultTheme,
        colors: {
            ...NavigationDefaultTheme.colors,
            background: theme.colors.white
        },
    };

    return (
        <SafeAreaProvider>
            <StatusBar backgroundColor={theme.colors.white} barStyle="dark-content"/>
            <NativeBaseProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <NavigationContainer
                        linking={{
                            enabled: true,
                            config: reactNavigationConfig,
                        }}
                        theme={navigationTheme}
                        documentTitle={{
                            formatter: (options, route) => {
                                return `${options?.title ?? route?.name} - ${appConfig.appName}`;
                            } 
                        }}
                    >
                        <Stack.Navigator>
                            <Stack.Screen name="MainScreens" component={MainScreens} options={{ headerShown: false }}/>
                            <Stack.Screen name="Wine" component={Wine} options={{ title: "Betöltés...", headerRight: () => <HomeButton/> }}/>
                            <Stack.Screen name="Winery" component={Winery} options={{ title: "Betöltés...", headerRight: () => <HomeButton/> }}/>
                            <Stack.Screen name="Place" component={Place} options={{ title: "Betöltés...", headerRight: () => <HomeButton/> }}/>
                            <Stack.Screen name="Page" component={Page} options={{ title: "Betöltés...", headerRight: () => <HomeButton/> }}/>
                        </Stack.Navigator>
                    </NavigationContainer>
                </QueryClientProvider>
            </NativeBaseProvider>
        </SafeAreaProvider>
    );
}
