import axios from 'axios';
import urls from './urls';

const apiClient = axios.create({
    baseURL: urls.apiBase,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: false
});

export default apiClient;