import React from "react";
import { Box, extendTheme, Input } from 'native-base';

export default function SearchBox({space, query, setQuery}) {
    return (
        <Box alignItems="center" ml={space} mr={space}>
            <Input variant="rounded" maxWidth={500} width="100%" placeholder="Keresés..." focusBorderColor="black" value={query} onChangeText={(text) => {setQuery(text);}}
                _focus={{
                    bg: 'transparent',
                    placeholderTextColor: 'text.400',
                    borderColor: 'muted.300',
                    _hover: {
                        borderColor: 'muted.300',
                    },
                    _stack: {
                        style: {
                            outline: 'none',
                        },
                    },
                }}
                _hover={{
                    borderColor: 'muted.300',
                }}
            />
        </Box>
    );
}
