import React from "react";
import { Box, Image, Stack, Text } from 'native-base';
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import urls from "../../services/urls";

export default function CarouselCard({image, title, onPress}) {
    const imageSource = {
        uri: urls.imageBase + '/' + image,
    }
    return (
        <TouchableWithoutFeedback onPress={onPress}>
            <Box width="150px" height="295px" bg="white" rounded="lg" borderColor="black" p={5} >
                <Stack space={5}>
                    <Image alignSelf="center" source={imageSource} alt=" " resizeMode="contain" width="100%" height="150px" rounded="md" />
                    <Text numberOfLines={4} hyphens='auto' color="gray.400">{title}</Text>
                </Stack>
            </Box>
        </TouchableWithoutFeedback>
    );
}
