import React from "react";
import Screen from "./Screen";
import { useQuery } from "react-query";
import ContentBlocks from "../content_blocks/ContentBlocks";
import { getWine } from "../../services/winery/wineApi";
import { useNavigation } from '@react-navigation/native';
import CenteredImage from "../content_blocks/CenteredImage";
import Title from "../content_blocks/Title";
import ReferenceBox from "../content_blocks/ReferenceBox";

export default function Wine({route}) {
    const navigation = useNavigation();
    const { data } = useQuery(['/wine', route.params.winerySlug, route.params.slug, 'getWine'], () => getWine(route.params.winerySlug, route.params.slug), {
        onSuccess: (data) => {
            navigation.setOptions({title: data?.title});
        },
        onError: () => {
            navigation.setOptions({title: 'Hiányzó oldal'});
        }
    });
    return (
        <Screen>
            {
                data &&
                <>
                    <CenteredImage image={data.image} width={data.imageWidth} height={data.imageHeight} />
                    <Title text={data.title + ' - ' + data.winery.title}/>
                    <ContentBlocks contentBlocks={data.contentBlocks}/>
                    <ReferenceBox {...data.winery} typeTitle="Pincészet" mt={0} onPress={() => navigation.push('Winery', {slug: data.winery.slug})}/>
                    <ReferenceBox {...data.place} typeTitle="Helység" mb={5} onPress={() => navigation.push('Place', {slug: data.place.slug})}/>
                </>
            }
        </Screen>
    );
}
