import React from "react";
import { Box, ScrollView } from 'native-base';

export default function Screen({children}) {
    return (
        <ScrollView bgColor="light.100" nestedScrollEnabled={true}>
            <Box bgColor="white" alignSelf={'center'} width={"100%"} maxWidth={1600}>
                {children}
            </Box>
        </ScrollView>
    );
}
