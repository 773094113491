import React from "react";
import Title from "./Title";
import Paragraph from "./Paragraph"
import Subtitle from "./Subtitle";
import HeaderImage from "./HeaderImage";
import ParagraphWithImage from "./ParagraphWithImage";
import Screen from "../screens/Screen";

export default function ContentBlocks({contentBlocks, mb=5}) {
    contentBlocks = contentBlocks.sort((a,b) => a.order - b.order);
    return (
        <Screen>
            {
                contentBlocks.map((contentBlock, index) => {
                    switch (contentBlock._type) {
                        case 'header_image':
                            return <HeaderImage key={contentBlock._id} {...contentBlock} mb={contentBlocks.length - 1 == index ? mb : null} />
                        case 'paragraph':
                            return <Paragraph key={contentBlock._id} {...contentBlock} mb={contentBlocks.length - 1 == index ? mb : null}/>
                        case 'paragraph_with_image':
                            return <ParagraphWithImage key={contentBlock._id} {...contentBlock} mb={contentBlocks.length - 1 == index ? mb : null}/>
                        case 'subtitle':
                            return <Subtitle key={contentBlock._id} {...contentBlock} mb={contentBlocks.length - 1 == index ? mb : null}/>
                        case 'title':
                            return <Title key={contentBlock._id} {...contentBlock} mb={contentBlocks.length - 1 == index ? mb : null}/>
                    }
                })
            }
        </Screen>
    );
}
