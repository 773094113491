import React from "react";
import Screen from "./Screen";
import { useQuery } from "react-query";
import ContentBlocks from "../content_blocks/ContentBlocks";
import { getPage } from "../../services/winery/pageApi";
import { useNavigation } from '@react-navigation/native';

export default function Page({route}) {
    const navigation = useNavigation();
    const { data } = useQuery(['/page', route.params.slug, 'getPage'], () => getPage(route.params.slug), {
        onSuccess: (data) => {
            navigation.setOptions({title: data?.title});
        },
        onError: () => {
            navigation.setOptions({title: 'Hiányzó oldal'});
        }
    });
    return (
        <Screen>
            {
                data &&
                <ContentBlocks contentBlocks={data.contentBlocks}/>
            }
        </Screen>
    );
}
