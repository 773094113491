import React from "react";
import { Image } from 'native-base';
import urls from "../../services/urls";

export default function CenteredImage({source, image, width, height, ml=5, mr=5, mt=5, mb=0}) {
    const imageSource = source ? source : {
        'uri': urls.imageBase + '/' + image,
    }
    return (
        <Image
            ml={ml} mr={mr} mt={mt} mb={mb}
            source={imageSource}
            maxHeight={height / 2}
            style={{ aspectRatio: width/height }}
            resizeMode= "contain"
            alt=" "
        />
    );
}
