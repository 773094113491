import React from "react";
import { IconButton } from 'native-base';
import { MaterialIcons } from "@expo/vector-icons";

export default function Carousel({direction, onPress}) {
    return (
        <IconButton position="absolute" left={direction=="backwards" ? "2.5" : undefined} right={direction=="forward" ? "2.5" : undefined} height={60} width={60} borderRadius={30} variant="solid" 
            _icon={{
                color: "grey.500",
                as: MaterialIcons,
                name: direction=="forward" ? "chevron-right" : "chevron-left",
                size: "3xl"
            }}
            _hover={{
                bg: "grey.600:alpha.20"
            }}
            _pressed={{
                bg: "grey.600:alpha.40"
            }}
            bg="grey.500:alpha.20"
            onPress={onPress}
        />
    );
}
